/**
 * List of menu items
 */
export interface MenuItem {
  label: string;
  path?: string;
  url?: string;
  submenu?: Array<{ label: string; path?: string; url?: string }>;
}

export const headerMenu: MenuItem[] = [
  {
    label: 'Home',
    path: '/',
  },
  {
    label: 'Solutions',
    submenu: [
      {
        label: 'Knowledge Hub & Docs',
        path: '/docs',
      },
      {
        label: 'Developer Dashboard',
        path: '/solutions/hoss-dashboard',
      },
      {
        label: 'API Analytics',
        path: '/analytics',
      },
      {
        label: 'Success Automation',
        path: '/solutions/hoss-success',
      },
      {
        label: 'API Tracking',
        path: '/solutions/api-tracking',
      },
    ],
  },
  {
    label: 'Resources',
    submenu: [
      {
        label: 'Blog',
        path: '/blog',
      },
      {
        label: 'Resources',
        path: '/resources',
      },
      // {
      //   label: 'Case Studies',
      //   path: '/resources/case-studies',
      // },
      {
        label: 'Hangouts',
        path: '/blog/hangouts',
      },
      // {
      //   label: 'ebooks',
      //   path: '/resources/ebooks',
      // },
      {
        label: 'DX Community',
        url: 'https://dx.community',
      },
    ],
  },
  {
    label: 'About',
    path: '/about',
  },
];

export const footerMenu: MenuItem[] = [
  {
    label: 'Home',
    path: '/',
  },
  {
    label: 'Solutions',
    submenu: [
      {
        label: 'Knowledge Hub & Docs',
        path: '/docs',
      },
      {
        label: 'Developer Dashboard',
        path: '/solutions/hoss-dashboard',
      },
      {
        label: 'API Analytics',
        path: '/analytics',
      },
      {
        label: 'Success Automation',
        path: '/solutions/hoss-success',
      },
      {
        label: 'API Tracking',
        path: '/solutions/api-tracking',
      },
    ],
  },
  {
    label: 'Resources',
    submenu: [
      {
        label: 'Blog',
        path: '/blog',
      },
      {
        label: 'Resources',
        path: '/resources',
      },
      // {
      //   label: 'Case Studies',
      //   path: '/resources/case-studies',
      // },
      {
        label: 'Hangouts',
        path: '/blog/hangouts',
      },
      // {
      //   label: 'ebooks',
      //   path: '/resources/ebooks',
      // },
      {
        label: 'DX Community',
        url: 'https://dx.community',
      },
    ],
  },
  {
    label: 'Marketplace',
    submenu: [
      {
        label: 'APIs',
        path: '/marketplace',
      },
      {
        label: 'Categories',
        path: '/marketplace/categories',
      },
    ],
  },
  {
    label: 'About',
    path: '/about',
  },
];
