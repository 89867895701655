/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import FooterWaveLg from '@assets/FooterWaveLg';
import Logo from '@assets/Logo';
import Container from '@components/Container';
import NavMenu from '@components/NavMenu';
import { footerMenu } from '@components/NavMenu/menu';
import theme from '@lib/theme';

const Footer: React.FC = () => {
  return (
    <footer
      css={css`
        min-height: 30vw;
        padding-bottom: 1.5rem;
        padding-top: 40vw;
        position: relative;

        @media (min-width: ${theme.breakpoints.md}) {
          padding-top: 4vw;
        }

        @media (min-width: ${theme.breakpoints.xl}) {
          padding-top: 4vw;
        }

        [data-nav-menu] {
          margin: 0 auto;

          li[data-align-right] {
            margin-left: inherit !important;
          }
        }
      `}
    >
      <Container>
        <div
          css={css`
            display: grid;
            grid-template-columns: 1fr 1fr;
            position: relative;
            grid-gap: 1rem;
            z-index: 10;

            @media (min-width: ${theme.breakpoints.md}) {
              grid-template-columns: 1fr;
            }

            [data-nav-menu] {
              justify-content: flex-start;
              order: 2;
              grid-row: span 2;

              @media (min-width: ${theme.breakpoints.md}) {
                order: 1;
                justify-content: center;
                grid-row: span 1;
              }

              ul {
                flex-direction: column;

                @media (min-width: ${theme.breakpoints.md}) {
                  flex-direction: row;
                }

                ul {
                  flex-direction: column;
                  padding-left: 0.5rem;
                }
              }
            }
          `}
        >
          <NavMenu isFooter menuItems={footerMenu} />

          <span
            css={css`
              order: 1;
              padding-bottom: 3rem;
              text-align: left;

              svg {
                width: 6.25rem;
              }

              @media (min-width: ${theme.breakpoints.md}) {
                padding: 3rem 0 2rem;
                order: 2;
                text-align: center;

                svg {
                  width: 10rem;
                }
              }
            `}
          >
          </span>

          <p
            css={css`
              font-size: 0.75rem;
              text-align: center;
              order: 3;
              margin-bottom: 0;
              text-align: left;

              @media (min-width: ${theme.breakpoints.md}) {
                text-align: center;
              }
            `}
          >
            Copyright &copy; Hoss Technologies, Inc. {new Date().getFullYear()}{' '}
            - All rights reserved.
          </p>
        </div>
      </Container>

      <FooterWaveLg />
    </footer>
  );
};

export default Footer;
