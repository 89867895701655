import { css } from '@emotion/react';

import theme from '@lib/theme';

export const globalStyles = css`
  /* http://meyerweb.com/eric/tools/css/reset/ 
     v2.0 | 20110126
     License: none (public domain)
  */
  html,
  body,
  div,
  span,
  applet,
  object,
  iframe,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  blockquote,
  pre,
  a,
  abbr,
  acronym,
  address,
  big,
  cite,
  code,
  del,
  dfn,
  em,
  img,
  ins,
  kbd,
  q,
  s,
  samp,
  small,
  strike,
  strong,
  sub,
  sup,
  tt,
  var,
  b,
  u,
  i,
  center,
  dl,
  dt,
  dd,
  ol,
  ul,
  li,
  fieldset,
  form,
  label,
  legend,
  table,
  caption,
  tbody,
  tfoot,
  thead,
  tr,
  th,
  td,
  article,
  aside,
  canvas,
  details,
  embed,
  figure,
  figcaption,
  footer,
  header,
  hgroup,
  menu,
  nav,
  output,
  ruby,
  section,
  summary,
  time,
  mark,
  audio,
  video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }

  article,
  aside,
  details,
  figcaption,
  figure,
  footer,
  header,
  hgroup,
  menu,
  nav,
  section {
    display: block;
  }

  body {
    line-height: 1;
  }

  ol,
  ul {
    list-style: none;
  }

  blockquote,
  q {
    quotes: none;
  }

  blockquote:before,
  blockquote:after,
  q:before,
  q:after {
    content: '';
    content: none;
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  /**
   * Global app styles
   */

  * {
    box-sizing: border-box;
  }

  html,
  body {
    -webkit-font-smoothing: antialiased;
    background-color: ${theme.colors.navy800};
    color: ${theme.colors.purple100};
    font-family: ${theme.fonts.sansSerif};
    font-weight: 500;
    line-height: 1.5;
    max-width: 100vw;
    min-height: 100vh;
    overflow-x: hidden;
    position: relative;
  }

  #___gatsby {
    max-width: 100vw;
    overflow-x: hidden;
  }

  a {
    color: ${theme.colors.gray100};
    transition: color 0.25s linear;

    &:hover,
    &:focus,
    &:active {
      color: ${theme.colors.purple400};
    }
  }

  button {
    font-family: ${theme.fonts.sansSerif};
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: bold;
    line-height: 1;
    letter-spacing: -0.03em;
  }

  h1 {
    color: ${theme.colors.gray100};
    font-size: 2.125rem;

    @media (min-width: ${theme.breakpoints.lg}) {
      font-size: 2.75rem;
    }
  }

  h2 {
    font-size: 1.5rem;
    padding-bottom: 5rem;
    text-align: center;
    text-transform: uppercase;
  }

  h3 {
    color: ${theme.colors.gray100};
    font-size: 2.75rem;
    line-height: 1;
  }

  p {
    font-size: 1rem;
    line-height: 1.5;
    margin-bottom: 1.5rem;
  }

  strong {
    font-weight: bold;
  }

  ::-webkit-input-placeholder {
    color: ${theme.colors.gray100};
  }

  ::-moz-placeholder {
    color: ${theme.colors.gray100};
  }

  :-ms-input-placeholder {
    color: ${theme.colors.gray100};
  }

  :-moz-placeholder {
    color: ${theme.colors.gray100};
  }

  [data-post-content] {
    .abyss {
      background-color: #13131f;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: ${theme.colors.gray100};
      padding-bottom: 0.5rem;
      padding-top: 1.5rem;
      text-align: left;
      text-transform: none;
    }

    h3,
    h4,
    h5,
    h6 {
      font-weight: 600;
    }

    h1 {
      font-size: 2.25rem;
    }

    h2 {
      font-size: 2rem;
    }

    h3 {
      font-size: 1.75rem;
    }

    h4 {
      font-size: 1.5rem;
    }

    h5 {
      font-size: 1.325rem;
    }

    h6 {
      font-size: 1.125rem;
    }

    a {
      color: ${theme.colors.blue600};
      text-decoration: none;
      transition: color 0.25s ease-in-out;

      &:hover,
      &:focus,
      &:active {
        color: ${theme.colors.purple400};
        text-decoration: underline;
      }
    }

    img {
      height: auto;
      border-radius: 1rem;
      max-width: 100%;

      &.left {
        float: left;
        margin-right: 1.5rem;
        margin-bottom: 1.5rem;
      }

      &.right {
        float: right;
        margin-left: 1.5rem;
        margin-bottom: 1.5rem;
      }
    }

    p,
    pre {
      margin-bottom: 1.5rem;
    }

    ol,
    ul {
      padding-left: 1.25rem;
    }

    ol {
      list-style: decimal;
      margin-bottom: 1.5rem;
    }

    ul {
      list-style: disc;
      margin-bottom: 1.5rem;

      ul {
        margin-bottom: 0;
      }
    }

    strong {
      font-weight: bold;
    }

    em {
      font-style: italic;
    }

    .anchor.before {
      color: ${theme.colors.purple300};
    }

    code {
      background-color: #13131f;
      border-radius: 0.25rem;
      padding: 0.125rem 0.25rem;
    }

    pre,
    code {
      font-family: Monaco, monospace;
    }

    hr {
      border-bottom-color: ${theme.colors.blue500};
      border-bottom-width: 2px;
      border-left: 0;
      border-right: 0;
      border-top: 0;
      opacity: 0.75;
    }

    blockquote {
      border-left: 2px solid ${theme.colors.purple500};
      color: ${theme.colors.purple200};
      padding-left: 1.5rem;
    }

    li p:nth-last-of-type(1) {
      margin-bottom: 0;
    }
  }
`;
