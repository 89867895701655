/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/react';
import { WindowLocation } from '@reach/router';
import { Link } from 'gatsby';

import Logo from '@assets/Logo';
import Container from '@components/Container';
import NavMenu from '@components/NavMenu';
import { headerMenu } from '@components/NavMenu/menu';
import theme from '@lib/theme';

interface Props {
  location: WindowLocation;
}

const Header: React.FC<Props> = ({ location }) => {
  return (
    <header
      css={css`
        padding-bottom: 1rem;
        padding-top: 1rem;

        @media (min-width: ${theme.breakpoints.lg}) {
          padding-bottom: 3.75rem;
        }
      `}
    >
      <Container>
        <div
          css={css`
            align-items: center;
            display: flex;
            flex-direction: row;

            img {
              height: auto;
              width: 8rem;
            }
          `}
        >
          <Link
            to="/"
            css={css`
              margin-right: 1.5rem;
            `}
          >
            <Logo />
          </Link>

          {/* {location.pathname !== '/signup' && (
            <NavMenu menuItems={headerMenu} />
          )} */}
        </div>
      </Container>
    </header>
  );
};

export default Header;
