import { css } from '@emotion/react';

import theme from '@lib/theme';

const styles = {
  container: css`
    display: flex;
    flex-direction: row;
    flex: 1;
    justify-content: flex-end;
    position: relative;
    z-index: 500;

    @media (min-width: ${theme.breakpoints.md}) {
      justify-content: flex-start;
    }

    a,
    button {
      appearance: none;
      background: none;
      border: 0;
      color: ${theme.colors.purple300};
      cursor: pointer;
      display: inline-block;
      font-size: 1rem;
      font-weight: 800;
      line-height: 1;
      outline: none;
      padding: 0.5rem;
      position: relative;
      text-decoration: none;
      transition: color 0.25s ease-in-out;

      &:hover,
      &:focus,
      &:active {
        color: ${theme.colors.gray100};
      }
    }

    ul {
      display: flex;
      flex-direction: row;
      justify-content: center;

      li {
        margin: 0 0.5rem 1rem;
        position: relative;
        width: 100%;

        @media (min-width: ${theme.breakpoints.md}) {
          margin: 0 0.5rem;
          width: auto;
        }
      }

      ul {
        display: flex;
        flex-direction: column;
        padding-top: 0.25rem;

        li {
          margin: 0;
          padding: 0.325rem 0;

          a,
          button {
            font-size: 0.875rem;
            width: 100%;
            display: block;
          }

          a:after {
            display: none !important;
          }
        }
      }
    }

    ul[data-isdropdown] {
      align-items: center;
      justify-content: flex-start;

      @media (max-width: ${theme.breakpoints.md}) {
        background-color: ${theme.colors.navy700};
        box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.08);
        display: flex;
        flex-direction: column;
        height: 100vh;
        left: 0;
        min-width: 45%;
        overflow-x: hidden;
        overflow-y: auto;
        padding: 2rem 2rem 2rem 1rem;
        position: fixed;
        top: 0;
        transform: translateX(-101%);
        transition: transform 0.25s ease-in-out;
        user-select: none;
        z-index: 100;

        &[data-nav-expanded] {
          transform: translateX(0);
          user-select: auto;
        }
      }

      @media (min-width: ${theme.breakpoints.md}) {
        align-items: center;
      }

      [data-align-right] {
        margin-left: auto !important;
      }

      a,
      button {
        color: ${theme.colors.purple200};

        @media (min-width: ${theme.breakpoints.md}) {
          color: ${theme.colors.purple100};
        }

        [data-caret] {
          color: ${theme.colors.gray100};
        }

        &:after {
          background-color: ${theme.colors.purple400};
          border-radius: 2px;
          bottom: 0;
          content: '';
          display: none;
          height: 2px;
          left: 0.5rem;
          max-width: 2rem;
          opacity: 0;
          position: absolute;
          transition: opacity 0.25s ease-in-out;
          width: 60%;

          @media (min-width: ${theme.breakpoints.md}) {
            display: block;
          }
        }

        &:hover,
        &:focus {
          color: ${theme.colors.gray100};
        }

        &.active,
        &[data-active='true'] {
          color: ${theme.colors.gray100};

          [data-caret] {
            color: ${theme.colors.gray100};
          }

          &:after {
            opacity: 1;
          }
        }
      }

      a[aria-expanded='true'],
      button[aria-expanded='true'] {
        color: ${theme.colors.gray100};
      }

      button + ul {
        display: none;
        height: 0;
        transition: height 0.25s ease-in-out;

        @media (min-width: ${theme.breakpoints.md}) {
          background-color: ${theme.colors.purple500};
          border-radius: 0.5rem;
          box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.08);
          display: none;
          left: 0;
          opacity: 0;
          padding: 0.5rem;
          position: absolute;
          top: 2.5rem;
          transition: opacity 0.25s ease-in-out;
          z-index: 50;
        }

        li {
          a,
          button {
            color: ${theme.colors.purple100};
          }
        }
      }

      button[aria-expanded='true'] + ul {
        display: block;
        height: auto;
        opacity: 1;

        @media (min-width: ${theme.breakpoints.md}) {
          width: 16rem;
        }
      }
    }

    ul[data-isfooter] {
      display: flex !important;
      align-items: center;

      a,
      button {
        font-size: 0.75rem;
        color: ${theme.colors.purple100};

        @media (min-width: ${theme.breakpoints.lg}) {
          font-size: 1rem;
        }

        [data-caret] {
          color: ${theme.colors.gray100};
          width: 0.625rem;
        }
      }

      [data-variant='primary'] {
        margin-left: 1rem;
      }

      li,
      li[data-align-right] {
        @media (max-width: ${theme.breakpoints.lg}) {
          margin: 0 0.5rem 0.25rem !important;
        }
      }

      li ul li {
        margin: 0 !important;
      }

      button + ul {
        display: none;
        height: 0;
        transition: height 0.25s ease-in-out;
        background-color: ${theme.colors.purple500};
        border-radius: 0.5rem;
        box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.08);
        display: none;
        left: 0;
        opacity: 0;
        padding: 0.5rem;
        position: absolute;
        top: 2rem;
        transition: opacity 0.25s ease-in-out;
        z-index: 50;

        li {
          a,
          button {
            color: ${theme.colors.purple100};
          }
        }
      }

      button[aria-expanded='true'] + ul {
        display: block;
        height: auto;
        opacity: 1;

        @media (min-width: ${theme.breakpoints.md}) {
          width: 16rem;
        }
      }
    }
  `,

  dropdownButton: css`
    svg {
      margin-left: 0.5rem;
      transform: rotate(180deg);
      width: 0.875rem;
    }

    &[aria-expanded='true'] {
      svg {
        transform: rotate(0);
      }
    }
  `,

  toggleButton: css`
    color: ${theme.colors.purple200};
    display: block;
    padding: 0.5rem !important;
    transition: color 0.25s ease-in-out;
    z-index: 100;

    svg {
      width: 1.75rem;
    }

    @media (min-width: ${theme.breakpoints.md}) {
      display: none !important;
    }
  `,

  bgOverlay: css`
    background-color: ${theme.colors.navy800};
    height: 100vh;
    left: 0;
    opacity: 0;
    pointer-events: none;
    position: fixed;
    top: 0;
    transition: opacity 0.25s ease-in-out;
    user-select: none;
    width: 100%;
    z-index: 99;

    &[data-active] {
      opacity: 0.75;
    }

    @media (min-width: ${theme.breakpoints.md}) {
      display: none;
    }
  `,
};

export default styles;
