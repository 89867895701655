/** @jsx jsx */
import { Helmet } from 'react-helmet';
import { css, Global, jsx } from '@emotion/react';
import { Location } from '@reach/router';
import { graphql, StaticQuery } from 'gatsby';

import Footer from '@components/Footer';
import Header from '@components/Header';

import * as styles from './styles';

interface Props {
  path: string;
  children: React.ReactChild;
}

const Layout: React.FC<Props> = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={(data) => (
      <Location>
        {({ location }) => (
          <div
            css={css`
              display: flex;
              flex-direction: column;
              min-height: 100vh;
            `}
          >
            <Helmet
              title={data.site.siteMetadata.title}
              meta={[
                {
                  name: 'keywords',
                  content:
                    'API, monitoring, reliability, tracking, third-party APIs',
                },
              ]}
            >
              <html lang="en" />
              <link rel="icon" href="/favicon.ico" />
            </Helmet>
            <Global styles={styles.globalStyles} />
            <Header location={location} />

            <div
              css={css`
                flex: 1;
              `}
            >
              {children}
            </div>

            {!location.pathname.startsWith('/signup') && <Footer />}
            <script
              src="https://cdn.ranksci.com/hoss-222929.min.js"
              type="text/javascript"
            ></script>
            <script src="//www.refersion.com/tracker/v3/pub_c63ec452ff847fa9b4c5.js"></script>
            <script
              dangerouslySetInnerHTML={{
                __html: `_refersion();`,
              }}
            ></script>
          </div>
        )}
      </Location>
    )}
  />
);

export default Layout;
