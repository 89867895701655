/** @jsx jsx */
import { css, jsx, SerializedStyles } from '@emotion/react';

interface Props {
  children: React.ReactChild;
  customStyles?: SerializedStyles;
}

const styles = css`
  margin: 0 auto;
  max-width: 70rem;
  width: 90%;
`;

const Container: React.FC<Props> = ({ children, customStyles }) => {
  return (
    <div
      css={css`
        ${styles};
        ${customStyles};
      `}
      data-component="container"
    >
      {children}
    </div>
  );
};

export default Container;
