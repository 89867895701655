/** @jsx jsx */
import { css, jsx } from '@emotion/react';

const FooterWaveLg: React.FC = () => {
  return (
    <svg
      css={css`
        bottom: 0;
        left: 0;
        margin-left: -10%;
        position: absolute;
        height: 120%;
        width: auto;
        pointer-events: none;
      `}
      fill="none"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1440 415"
    >
      <g filter="url(#footerwavelg-filter-0)">
        <mask id="footerwavelg-mask-a" fill="#fff">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0 38.505l60 25.67c60 25.67 180 77.011 300 83.428 120 6.418 231-45.884 351-52.302 120-6.417 249 33.05 369 20.214 120-12.835 240-64.175 300-89.845L1440 0v415H0V38.505z"
          />
        </mask>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 38.505l60 25.67c60 25.67 180 77.011 300 83.428 120 6.418 231-45.884 351-52.302 120-6.417 249 33.05 369 20.214 120-12.835 240-64.175 300-89.845L1440 0v415H0V38.505z"
          fill="url(#footerwavelg-0)"
        />
        <path
          clipRule="evenodd"
          d="M0 38.505l60 25.67c60 25.67 180 77.011 300 83.428 120 6.418 231-45.884 351-52.302 120-6.417 249 33.05 369 20.214 120-12.835 240-64.175 300-89.845L1440 0v415H0V38.505z"
          stroke="url(#footerwavelg-1)"
          strokeWidth="4"
          mask="url(#footerwavelg-mask-a)"
        />
      </g>
      <g filter="url(#footerwavelg-filter-1)">
        <mask id="footerwavelg-mask-b" fill="#fff">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0 102l48 12.069c48 11.265 144 34.599 240 86.9 96 51.496 192 132.764 288 138.396 96 6.437 192-63.566 288-103.797 96-41.036 192-52.301 288-63.565 96-12.07 192-23.335 240-28.967l48-6.437V415H0V102z"
          />
        </mask>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 102l48 12.069c48 11.265 144 34.599 240 86.9 96 51.496 192 132.764 288 138.396 96 6.437 192-63.566 288-103.797 96-41.036 192-52.301 288-63.565 96-12.07 192-23.335 240-28.967l48-6.437V415H0V102z"
          fill="url(#footerwavelg-2)"
        />
        <path
          clipRule="evenodd"
          d="M0 102l48 12.069c48 11.265 144 34.599 240 86.9 96 51.496 192 132.764 288 138.396 96 6.437 192-63.566 288-103.797 96-41.036 192-52.301 288-63.565 96-12.07 192-23.335 240-28.967l48-6.437V415H0V102z"
          stroke="url(#footerwavelg-3)"
          strokeWidth="4"
          mask="url(#footerwavelg-mask-b)"
        />
      </g>
      <defs>
        <radialGradient
          id="footerwavelg-0"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(34.00014 263.40975 -4110.56863 530.5798 686 39.77)"
        >
          <stop stopColor="#3C3C6A" />
          <stop offset=".923" stopColor="#272740" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="footerwavelg-2"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="rotate(80.288 264.748 472.686) scale(201.556 4119.16)"
        >
          <stop stopColor="#3C3C6A" />
          <stop offset=".923" stopColor="#272740" stopOpacity="0" />
        </radialGradient>
        <linearGradient
          id="footerwavelg-1"
          x1="688"
          y1="71.472"
          x2="713.958"
          y2="289.54"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4A4A83" />
          <stop offset=".708" stopColor="#272740" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="footerwavelg-3"
          x1="688"
          y1="155.906"
          x2="702.856"
          y2="321.373"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4A4A83" />
          <stop offset=".708" stopColor="#272740" stopOpacity="0" />
        </linearGradient>
        <filter
          id="footerwavelg-filter-0"
          x="-8"
          y="-8"
          width="1456"
          height="431"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" />
          <feGaussianBlur stdDeviation="4" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_backgroundBlur"
            result="shape"
          />
        </filter>
        <filter
          id="footerwavelg-filter-1"
          x="-8"
          y="94"
          width="1456"
          height="329"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" />
          <feGaussianBlur stdDeviation="4" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_backgroundBlur"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

export default FooterWaveLg;
