const theme = {
  boxShadow: 'box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.08)',
  borderRadius: '1.875rem',
  colors: {
    blue200: '#5BB3FF',
    blue300: '#5CB4FF',
    blue400: '#447EF2',
    blue500: '#3C3C6A',
    blue600: '#598FFB',

    gray100: '#F5F5FC',

    pink500: '#EB38A3',

    purple100: '#BDBDFF',
    purple200: '#7A7AB7',
    purple300: '#575684',
    purple400: '#7f6ff6',
    purple500: '#3C3C6A',
    purple600: '#36365E',

    navy600: '#3A3A60',
    navy700: '#323450',
    navy800: '#282942',
    navy900: '#1d1e33',

    red500: '#F96879',

    green300: '#3AF1E7',
  },
  breakpoints: {
    xs: '27.5rem',
    sm: '34rem',
    md: '43.25rem',
    lg: '60.5rem',
    xl: '80rem',
  },
  fonts: {
    sansSerif: '"Catamaran", sans-serif',
  },
};

export default theme;
