/** @jsx jsx */
import { useEffect, useRef, useState } from 'react';
import { css, jsx } from '@emotion/react';
import { Location } from '@reach/router';
import { Link } from 'gatsby';

import Caret from '@assets/icons/Caret';
import Close from '@assets/icons/Close';
import Hamburger from '@assets/icons/Hamburger';
import { styledButton } from '@components/Button';
import { MenuItem } from '@components/NavMenu/menu';
import styles from '@components/NavMenu/styles';
import theme from '@lib/theme';

interface Props {
  isDropdown?: boolean;
  isFooter?: boolean;
  menuItems: MenuItem[];
}

const NavMenu: React.FC<Props> = ({
  isDropdown = true,
  isFooter = false,
  // menuItems,
}) => {
  const dropdown = useRef<HTMLElement | null>(null);
  const [expanded, setExpanded] = useState<string>('');
  const [navExpanded, setNavExpanded] = useState<boolean>(false);

  // Is this menu item expanded
  const isExpanded = (label: string): boolean => {
    return !isDropdown || expanded === label;
  };

  // Close if already open, expand if not
  const toggleExpanded = (label: string): void => {
    if (expanded === label) {
      return setExpanded('');
    }

    return setExpanded(label);
  };

  // Close when the user clicks outside the menu if it's already expanded
  const clickOutside = (e: MouseEvent): void => {
    if (navExpanded && !dropdown?.current?.contains(e.target as Node)) {
      setNavExpanded(false);
    }

    if (expanded && !dropdown?.current?.contains(e.target as Node)) {
      return setExpanded('');
    }
  };

  // Close when the user hits the escape key, if menu is already expanded
  const closeOnEsc = (e: KeyboardEvent): void => {
    const key = e.key || e.keyCode;

    if (key === 'Escape' || key === 'Esc' || key === 27) {
      setNavExpanded(false);
      return setExpanded('');
    }
  };

  useEffect(() => {
    document.addEventListener('click', clickOutside);
    document.addEventListener('keyup', closeOnEsc);

    return () => {
      document.removeEventListener('click', clickOutside);
      document.removeEventListener('keyup', closeOnEsc);
    };
  });

  return (
    <Location>
      {({ location }) => (
        <nav css={styles.container} ref={dropdown} data-nav-menu="">
          {isDropdown && !isFooter ? (
            <button
              css={styles.toggleButton}
              onClick={() => setNavExpanded(!navExpanded)}
            >
              {navExpanded ? <Close /> : <Hamburger />}
            </button>
          ) : null}
          <ul
            css={css`
              flex: 1;
            `}
            data-nav-expanded={navExpanded ? '' : undefined}
            data-isdropdown={isDropdown && !isFooter ? '' : undefined}
            data-isfooter={isFooter ? '' : undefined}
          >
            
          </ul>
          <div
            css={styles.bgOverlay}
            data-active={navExpanded ? '' : undefined}
          />
        </nav>
      )}
    </Location>
  );
};

export default NavMenu;
