/** @jsx jsx */
import { css, jsx } from '@emotion/react';

const Hamburger: React.FC = () => {
  return (
    <svg
      viewBox="0 0 24 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      css={css`
        height: auto;
        width: 1.5rem;
      `}
    >
      <rect x="4" width="16" height="2" rx="1" fill="currentColor" />
      <rect y="7" width="24" height="2" rx="1" fill="currentColor" />
      <rect x="4" y="14" width="16" height="2" rx="1" fill="currentColor" />
    </svg>
  );
};

export default Hamburger;
