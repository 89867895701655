/** @jsx jsx */
import { css, jsx } from '@emotion/react';

const styles = css`
  height: auto;
  margin-left: -1rem;
  width: 10rem;
`;

const Logo: React.FC = () => {
  return (
    <svg
      css={styles}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 2000 1000"
    >
      <defs />
      <path
        fill="#fff"
        d="M1114.64 338.36c-39.52 29.64-62.23 120.83-70.84 165.83-6.86 35.85-20.24 107.24-20 158.79.08 21 5.4 41 19.91 50.89 11.29 7.67 33.24 9.43 47.8-8.34 12.52-15.27 15.24-54.69 15.33-72.94.21-42.69 3.67-99.19 15-144.37 3.06-12.52 47.6-190.29-7.2-149.86zM1480.33 352.52C1434.84 368.2 1369 392 1363.42 441.79c-1.91 17.13 11.56 39.53 37.56 68.53 16 17.88 44.64 41.05 58.27 56.77 21.08 23.56-55.61 62.78-93 89.3-62.24 44.12 13.64 84.34 83 46.79 40.32-21.82 68.93-36.58 101.77-70.36 43.41-44.65 9.35-74.22-25.38-110.19-18-18.59-50.28-49.25-48.47-72.12 2.12-26.78 50-44.45 65.17-50.86 52.57-22.21 59.67-38.83 41-50.54-14.57-9.11-41.78-13.23-103.01 3.41z"
      />
      <path
        fill="#fff"
        d="M1639.94 438.36c-30.62 4.7-84.32 14.29-98.38 48.87-12.32 30.27 23.09 57.47 45.66 77 18 15.56 34.37 42.07-9.75 77-30.26 23.93-50.32 34.74-51.25 59.89-1.24 33.49 52.74 40.47 85.58 28.53 54.57-19.85 94.53-53.15 115.28-95.45 28.55-58.16-39.62-81.92-74.35-101.76-36.25-20.72-22.73-31.4 3.58-42.61 57.2-24.45 58.44-62.89-16.37-51.47zM1360 472.83c-38.57-45.6-125-32.8-180.42-2-39.39 21.88-109.82 69.52-232.74 158.33-3.31-64 6.25-120.89 13.28-190.07 1.67-16.46 8.12-52.55-3.58-74.37-4.39-8.19-14.23-14.87-27.25-15.75-11.77-.79-29.88 4.57-37.08 28.09-8.89 22.95-18.64 106.07-23.77 165.63-3.9 45.29-8 94.07-1.56 141 12 87.12 170.81-50.71 211-82.1 57.1-44.61 91.66-69.18 109.43-66.93-37.31 29.87-50.49 99.84-14.73 140.18 42.76 48.24 145.39-6.33 177.34-46.16 34.53-43.02 45.82-113.59 10.08-155.85z"
      />
      <ellipse fill="#fff" cx="1794.5" cy="411.16" rx="38.5" ry="40.58" />
      <path
        d="M551.85 477.76C541.91 448 517.26 427 488.42 427c-32.18 0-59.15 26.23-66.26 61.5a94.08 94.08 0 001.95 44.95c9.28 31.24 34.57 53.65 64.31 53.65s55.27-22.62 64.44-54.08a93.7 93.7 0 00-1-55.21zm-59.44 61.62c-19.14 0-34.65-18.18-34.65-40.62s15.51-40.62 34.65-40.62 34.65 18.19 34.65 40.62-15.51 40.62-34.65 40.62z"
        fill="#ff00a6"
      />
      <path
        fill="#fff"
        d="M754 529.23a275.41 275.41 0 01-100.71 213.19c-6.63-48.88-14.87-103.23-20.51-128a847.15 847.15 0 00-24.5-85.13c7.63-.72 15.19-1.64 22.56-2.87 32.94-5.5 57.11-26.82 61.45-59.78 2.18-16.52 3.45-26.67 4.2-45.46.42-10.63.57-36.25 0-40.3-4.45 9.46-10.57 19.56-15.67 29.17-10.44 19.67-31.69 43.86-53.93 47.45-11.59 1.87-24.13 1.24-35.69-.82-5.23-.93-10.51-2.14-15.76-3.48-17.31-25.22-46.24-50.13-87.15-51.92h-4.65c-40.8 1.79-69.68 26.58-87 51.73-5.49 1.41-11 2.7-16.47 3.67-11.56 2.06-24.11 2.69-35.7.82-22.22-3.64-43.47-27.83-53.92-47.5-5.1-9.61-11.2-19.71-15.67-29.17-.54 4.05-.4 29.67 0 40.3.76 18.79 2 28.94 4.2 45.46 4.35 33 28.52 54.28 61.46 59.78 7.56 1.27 15.29 2.2 23.1 2.93a848.39 848.39 0 00-24.46 85.07c-6.08 26.73-15.17 87.71-22 139.21A275.68 275.68 0 01202 529.23c0-152.42 123.56-276 276-276s276 123.58 276 276z"
      />
      <path
        fill="#fff"
        d="M433.38 534.24c-3.05-.31-6.14-.6-9.27-.84a94.08 94.08 0 01-1.95-44.95zM556.53 507a92.6 92.6 0 01-3.67 26c-5 .36-10 .77-14.84 1.27l13.83-56.48a92.73 92.73 0 014.68 29.21z"
      />
      <path
        fill="#fff"
        d="M551.85 477.76C541.91 448 517.26 427 488.42 427c-32.18 0-59.15 26.23-66.26 61.5a94.08 94.08 0 001.95 44.95c9.28 31.24 34.57 53.65 64.31 53.65s55.27-22.62 64.44-54.08a93.7 93.7 0 00-1-55.21zm-59.44 61.62c-19.14 0-34.65-18.18-34.65-40.62s15.51-40.62 34.65-40.62 34.65 18.19 34.65 40.62-15.51 40.62-34.65 40.62z"
      />
      <path
        fill="#fff"
        d="M551.85 477.76C541.91 448 517.26 427 488.42 427c-32.18 0-59.15 26.23-66.26 61.5a94.08 94.08 0 001.95 44.95c9.28 31.24 34.57 53.65 64.31 53.65s55.27-22.62 64.44-54.08a93.7 93.7 0 00-1-55.21zm-59.44 61.62c-19.14 0-34.65-18.18-34.65-40.62s15.51-40.62 34.65-40.62 34.65 18.19 34.65 40.62-15.51 40.62-34.65 40.62z"
      />
      <path
        fill="none"
        d="M551.85 477.76C541.91 448 517.26 427 488.42 427c-32.18 0-59.15 26.23-66.26 61.5a94.08 94.08 0 001.95 44.95c9.28 31.24 34.57 53.65 64.31 53.65s55.27-22.62 64.44-54.08a93.7 93.7 0 00-1-55.21zm-59.44 61.62c-19.14 0-34.65-18.18-34.65-40.62s15.51-40.62 34.65-40.62 34.65 18.19 34.65 40.62-15.51 40.62-34.65 40.62z"
      />
      <path
        fill="none"
        d="M754 529.23a275.41 275.41 0 01-100.71 213.19c-6.63-48.88-14.87-103.23-20.51-128a847.15 847.15 0 00-24.5-85.13c7.63-.72 15.19-1.64 22.56-2.87 32.94-5.5 57.11-26.82 61.45-59.78 2.18-16.52 3.45-26.67 4.2-45.46.42-10.63.57-36.25 0-40.3-4.45 9.46-10.57 19.56-15.67 29.17-10.44 19.67-31.69 43.86-53.93 47.45-11.59 1.87-24.13 1.24-35.69-.82-5.23-.93-10.51-2.14-15.76-3.48-17.31-25.22-46.24-50.13-87.15-51.92h-4.65c-40.8 1.79-69.68 26.58-87 51.73-5.49 1.41-11 2.7-16.47 3.67-11.56 2.06-24.11 2.69-35.7.82-22.22-3.64-43.47-27.83-53.92-47.5-5.1-9.61-11.2-19.71-15.67-29.17-.54 4.05-.4 29.67 0 40.3.76 18.79 2 28.94 4.2 45.46 4.35 33 28.52 54.28 61.46 59.78 7.56 1.27 15.29 2.2 23.1 2.93a848.39 848.39 0 00-24.46 85.07c-6.08 26.73-15.17 87.71-22 139.21A275.68 275.68 0 01202 529.23c0-152.42 123.56-276 276-276s276 123.58 276 276z"
      />
      <text
        transform="translate(1856.24 370.49)"
        fontSize="50"
        fill="#f6f6f6"
        fontFamily="MyriadPro-Regular,Myriad Pro"
      >
        TM
      </text>
    </svg>
  );
};

export default Logo;
